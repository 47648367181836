import React from "react"
import { Link } from "gatsby"
import StateAV from "./state-av"

const Footer = () => {
  return (
    <div className="footer">
      <StateAV />
      <div className="footer_holder">
        <div className="column">
          <div className="wrap">
            <div className="navigation_footer">
              <div className="item">
                <Link to="/contact-us">Contact Us</Link>
              </div>
              <div className="item">
                <Link to="/terms">Terms</Link>
              </div>
              <div className="item">
                <Link to="/privacy">Privacy</Link>
              </div>
              <div className="item">
                <a href="https://epoch.com/">Epoch billing support</a>
              </div>
              <div className="item">
                <a href="https://support.ccbill.com/">CCBill billing support</a>
              </div>
              <div className="item">
                <Link to="/join-application">Become an AddFriends Creator</Link>
              </div>
              <div className="item">
                <Link to="/report-content">Report Content</Link>
              </div>
              <div className="item">
                <Link to="/2257">
                  18 U.S.C. 2257 Record-Keeping Requirements Compliance
                  Statement
                </Link>
              </div>
            </div>
            {/* <div className="bottom">
              <p>
                All graphics referring to SnapChat, Instagram, and other Third
                Party platforms are owned by their respective trademark holder.
                AddFriends.com is not explicitly endorsed by Snapchat, Instagram
                or by any other Third Party platform.
              </p>
            </div> */}
            <div className="copy">
              Copyright {new Date().getFullYear()} AddFriends.com - All Rights
              Reserved
            </div>
          </div>
        </div>
        <div className="column second">
          <div className="logo_footer">
            <Link to="/">
              <em>+</em>Add<strong>Friends</strong>.com
            </Link>
          </div>
          <div className="copy_mobile">
            Copyright {new Date().getFullYear()} AddFriends.com - All Rights
            Reserved
          </div>
          <div className="navigation_social">
            <a href="https://twitter.com/AddFriendscom">
              <i className="icon-twitter" />
            </a>
            <a href="https://www.snapchat.com/add/addfriendsnow">
              <i className="icon-snapchat" />
            </a>
            <a href="https://www.instagram.com/addfriendsnow/">
              <i className="icon-inst" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
