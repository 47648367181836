import React, { useEffect, useState } from "react"

const StateAV = () => {
  const [showWarning, setShowWarning] = useState(false)
  const [theState, setTheState] = useState("")
  useEffect(() => {
    fetch(`https://addfriends.com/tm/s.php`)
      .then(res => res.json())
      .then(res => {
        //handle
        if ("sfw" in res) {
          if (res.sfw) {
            setShowWarning(true)
            setTheState(res.full)
          }
        }
      })
      .catch(() => {
        console.log("there was an error")
      })
  }, [])
  if (!showWarning) return null

  return (
    <div className="state-av">
      <div className="state-top">
        <h4>ATTN: {theState}</h4>
        <h5>AGE VERIFICATION REQUIRED</h5>
      </div>

      <p>
        You must verify your age to access AddFriends.com. {theState} law now
        requires us to implement a process for verifying the age of users who
        visit our site. We will verify your age after you log in.
      </p>
      <p>
        The privacy and security of our community is our top priority, and we
        thank you for your cooperation.
      </p>
      <p>
        We guarantee that AddFriends.com does not collect any data during this
        process. The verification process is carried out by reputable service
        providers who specialize in verifying the age of online users.
      </p>
    </div>
  )
}

export default StateAV
